<template>
  <ftx-dialog dialog="location" position="bottom" maximized>
    <template v-slot:DialogContent>
      <q-card style="min-height: 150px" class="mobileCardDialogue">
        <div v-if="isMobile" class="mobileDialogTitle">
          <!-- <h5 class="text-h5">Location</h5> -->
          <q-icon
            class="close cursor-pointer text-dark"
            name="close"
            v-close-popup
          />
        </div>
        <LocationDialogContent parentDialog="location" />
      </q-card>
    </template>
  </ftx-dialog>
</template>

<script>
import LocationDialogContent from './LocationDialogContent'
export default {
  components: { LocationDialogContent },
}
</script>
